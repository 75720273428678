<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-06 11:19:43
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-24 15:09:19
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/Supplier/RequestTableItem/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="request-table-item">
    <div class="left">
      {{ numFormat(row.advRequest || 0, 3, '') }}
    </div>
    <div class="right">
      <!-- <a-button type="primary" v-if="isGeneral && isPlat">请充值</a-button> -->
      <!-- <SimpleChart v-else :dateList="dateList" :dataList="dataList" title="广告请求数"/> -->
      <SimpleChart :dateList="dateList" :dataList="dataList" title="广告请求数"/>
    </div>
  </div>
</template>

<script>
import SimpleChart from '../SimpleChart'
import { numFormat } from '@/utils/dealNumber'
import { mapState } from 'vuex'
export default {
  name: 'RequestTableItem',
  components: { SimpleChart },
  props: {
    row: {
      default: () => ({
        request: 0,
        advRequestList: []
      }),
      type: Object
    }
  },
  computed: {
    dateList () {
      return (this.row.advRequestList || []).map((item) => item.date)
    },
    dataList () {
      return (this.row.advRequestList || []).map((item) => item.advRequest)
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      roleId: (state) => state.user.roleId,
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    // 是否是普通用户
    isGeneral () {
      return this.roleId !== 6 && this.role !== 'SUPER_ADMIN'
    },
    // 是否是平台开放供应商
    isPlat () {
      return +this.row.sourceType === 1
    }
  },
  methods: {
    numFormat
  }
}
</script>

<style lang="less" scoped>
.request-table-item{
  width: 100%;
  position: relative;
  display: flex;
  .left{
    width: 55%;
    line-height: 40px;
  }
  .right{
    width: 0;
    flex: 1 auto;
    height: 40px;
  }
}
</style>
