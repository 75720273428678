<!--
 * @Author: zhoutao mrzater@163.com
 * @Date: 2024-09-06 14:48:37
 * @LastEditors: zhoutao mrzater@163.com
 * @LastEditTime: 2024-09-06 16:34:01
 * @FilePath: /mediatom-web/src/viewsForManage/Flow/Supplier/SimpleChart/index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="simple-chart-container">
    <Chart :option="option" />
  </div>
</template>

<script>
import option from './options'
import Chart from '@/components/common/Chart'
import { numFormat } from '@/utils/dealNumber'
export default {
  components: { Chart },
  data () {
    return {
      option
    }
  },
  props: {
    dateList: {
      default: () => ([]),
      type: Array
    },
    dataList: {
      default: () => ([]),
      type: Array
    },
    title: {
      default: '',
      type: String
    }
  },
  watch: {
    dateList: {
      handler () {
        const op = JSON.parse(JSON.stringify(this.option))
        op.xAxis.data = this.dateList
        op.series[0].data = this.dataList
        op.tooltip.formatter = (params) => {
          let str = ''
          params.forEach((item, index) => {
            str += item.marker + this.title + ': ' + numFormat(params[0].data, 3, '') + '<br>'
          })
          return params[0].axisValue + '<br>' + str
        }
        this.option = op
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="less" scoped>
.simple-chart-container{
  width: 100%;
  height: 100%;
}
</style>
